import React from 'react';
import { Link } from 'react-router-dom';
import AboutImage from '../images/aboutimg.webp';
import SevenDaysWIP from '../images/7DaysNoChars.gif';
import ASTSitLogo from '../images/ASTSitLogo.webp';
import TwiceUponATime from '../images/TwiceUponATime.png';
import icindlogo from '../images/icindustrieslogo.webp'
import HomeBack from '../images/BackgroundHome.png'
import ScrollToTop from './ScrollToTop';
import './HomePage.css';

const MainContainer = () => {
  return (
    <div className="main-container">
      <ScrollToTop />
      <div className="hero-section">
        <img src={icindlogo} alt="IC Industries Logo" className='icind-logo' />
        <img src={HomeBack} alt="Homepage Background" className='home-background' />
          <div className='hero-content'>
            <a href="https://ic-industries.itch.io/the-last-sit" target='_blank' rel='noreferrer'>
              <img src={ASTSitLogo} alt="The Last Sit" className="hero-image" />
            </a>
            <a href="/sevendays" target='_blank' rel='noreferrer'>
              <img src={SevenDaysWIP} alt="7 Days WIP" className="hero-image2" />
            </a>
            <a href="https://ic-industries.itch.io/tuatip" target='_blank' rel='noreferrer'>
              <img src={TwiceUponATime} alt="Twice Upon a Time" className="hero-image" />
            </a>
          </div>
          <h4 className='ic-ind-desc'>IC Industries specializes in creating and producing original game music across a wide range of styles.</h4>
        </div>
        <div className="content-container">
        <div className="work-container">
          <h2>Check out what we've worked on.</h2>
          <p>Our own games, and games we've made music for.</p>
          <Link to="/games" className="button-home">Games</Link>
          <Link to="/sevendays" className="button-home">7 Days</Link>
        </div>
        <div className="about-container">
          <img src={AboutImage} alt="About" className="about-image" />
          <div className="about-text">
          <h2>About <i>Upcoming 7 Days</i></h2>
          <p>
            7 Days by IC Industries immerses you in a world where dreams and reality blur. Play as a boy exploring his daily memories and making choices that shape his journey 
            through school, family, love, and loss. With hand-drawn art and atmospheric music, each day uncovers new facets of his life, challenging him to find hope or face 
            darkness.
          </p>
          <Link to="/sevendays" className="button-home">Learn More</Link>
          <br /> <br /> <br />
          <h2>About <i>IC Industries</i></h2>
            <p>
              IC Industries specializes in crafting and producing exceptional game music, bringing rich and immersive soundscapes to every project. Our diverse portfolio includes 
              acclaimed titles such as 'Twice Upon a Time in Pikoh,' 'Underneath the Stars,' and 'Mech Survivor Elite.' We blend creativity and technical expertise to enhance 
              gameplay experiences and create memorable audio environments.
            </p>
            <Link to="/about" className="button-home">Learn More</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContainer;